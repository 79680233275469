// import { createHash } from 'node:crypto' // WARNING: STANDARD LIB FROM NODE IMPORT. HERE BECAUSE OTHER LIBS (AB)USE IT

import type {
  ActionType,
  RecordingEventByActionType,
} from '@/types/recorder'

import { filter, map, merge } from 'rxjs'
import { DOMSerializer } from '../../util/domSerializer'
import { getTimestamp } from './helpers'
import { createDOMEventObservables } from './observables/events'
import { createFirstEventObservable } from './observables/first'
import { createMutationObservable } from './observables/mutation'
import { createCSSOMObservable } from './observables/styles'

function addTimestamp(event: Omit<RecordingEventByActionType, 'timestamp'>) {
  return { ...event, timestamp: getTimestamp() } as RecordingEventByActionType
}

// This is the main function that creates the observable that records everything
// It should be self-explanatory /s
export function createRecorderObservable(window: Window, document: Document, domSerializer: DOMSerializer = new DOMSerializer(), { disableWatch = {} }: { disableWatch?: { [_key in ActionType]?: boolean } } = {}) {
  const firstEvents$ = createFirstEventObservable({ window, document })

  const css$ = createCSSOMObservable({ window, document, domSerializer })

  const domEventsObservable$ = createDOMEventObservables({ window, document, domSerializer })

  const mutation$ = createMutationObservable({ domSerializer, document })

  return merge(firstEvents$, css$, ...domEventsObservable$, mutation$).pipe(
    filter(ev => !disableWatch[ev.type]), // remove events that are being ignored in config
    map(addTimestamp), // as the events come in, they will get timestamps attached to them
  )
}
