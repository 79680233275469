import type { OnLoadArguments, SetupArguments } from '@/types/setup'
import type { Observable } from 'rxjs'
import type { EngineCodelessEvent } from './engine'
import { makeBufferedObservable } from '@/util/rxjs'
import { createEngineMapper } from './engine'
import { createCodelessEventsObservable } from './events'

export async function onSetupEvent({
  // sessionId,
  metricsPromise,
}: SetupArguments) {
  const {
    activeEngines,
  } = (await metricsPromise ?? {})

  // init the engine, modify page with edits from specified option that the server provides
  // if (engines) {
  //   return {
  //     activeEngines: initEngine(sessionId, engines),
  //   }
  // }

  return {
    activeEngines,
  }
}

export async function onSessionLoad({ sessionId, metricsPromise, sessionEnding$ }: OnLoadArguments): Promise<{
  codelessEvents$?: Observable<EngineCodelessEvent[]>
}> {
  const {
    eventListeners,
    activeEngines,
  } = (await metricsPromise ?? {})

  // Make observables for the event listeners
  // console.log('Event listeners', eventListeners) // TODO nice lil debug message to be removed from prod
  if (eventListeners) {
    // Just like the recorder, this is a big function that does a lot of things
    const eventsObservable$ = createCodelessEventsObservable(eventListeners, sessionId)
    // console.log('Events observable again main', eventsObservable$) // TODO nice lil debug message to be removed from prod

    // Just like the recorder, we stitch together observables to make sure we don't lose events
    // and have some nice buffering/throttling to smooth over network requests
    const eventsEngineObservable$ = eventsObservable$.pipe(createEngineMapper(sessionId, activeEngines))

    const bufferedEventsEngineObservable$ = makeBufferedObservable(eventsEngineObservable$, sessionEnding$, 0)
    return {
      codelessEvents$: bufferedEventsEngineObservable$,
    }
  }
  return {}
}

// export async function onSessionLoad() {
//   return {
//     o2: true
//   }
// }
