// this is needed to make vite bundling work with a global
import type { PushArguments } from '@/types/network'

import type {
  RecordingEventByActionType,
} from '@/types/recorder'
import type { SortableId } from '@/util/id'

import type { PutRecordsRequestEntry } from '@aws-sdk/client-kinesis'
import { IDENTITY_POOL_ID, MAX_CHUNK_SIZE, STREAM_NAME, STREAM_REGION } from '@/const'
import { counter } from '@/util/counter'
import { KinesisClient, PutRecordCommand, PutRecordsCommand } from '@aws-sdk/client-kinesis'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'
import { encodeToUint8Array } from '../util/string'
import { PushQueue } from './queue'

const kinesisClient = new KinesisClient({
  region: STREAM_REGION,
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: STREAM_REGION },
    identityPoolId: IDENTITY_POOL_ID,
  }),
  endpoint: import.meta.env.MODE === 'development' ? (import.meta.env.KINESIS_ENDPOINT ?? import.meta.env.VITE_KINESIS_ENDPOINT) : undefined,
})

const recordingEventsCounter = counter()

// This is a function that needs an exact mirror on the kinesis side. It serializes events into a smaller structure that works with JSON
function shrink(events: RecordingEventByActionType[]) {
  return events.map(
    ({ type, timestamp, data }) =>
      [type, Math.round(timestamp), data] as const,
  )
}

function flushRecords(records: PutRecordsRequestEntry[]) {
  return kinesisClient.send(new PutRecordsCommand({
    Records: records,
    StreamName: STREAM_NAME,
  }))
}

function flushRecord(record: PutRecordsRequestEntry) {
  return kinesisClient.send(new PutRecordCommand({
    ...record,
    StreamName: STREAM_NAME,
  }))
}

function getRequestEntry({ key, token, sessionId, pageId, pageUrl }: PushArguments, startTimestamp: number, endTimestamp: number, generateOrderKey: () => SortableId | string | number, data: any): PutRecordsRequestEntry & { SequenceNumberForOrdering: string } {
  const orderKey = generateOrderKey()
  return {
    Data: encodeToUint8Array(
      `${token},${sessionId},${pageId},${orderKey},"${pageUrl}",${startTimestamp},${endTimestamp}::${data}`,
    ),
    PartitionKey: `${key}#${sessionId}`,
    // StreamName: STREAM_NAME,
    SequenceNumberForOrdering: orderKey.toString(),
  }
}

// export function pushRecordingEvents(pushArgs: PushArguments, events: RecordingEventByActionType[], generateOrderKey: () => SortableId | string | number = recordingEventsCounter.increment) {
//   const shrunkEvents = shrink(events)
//   const jsonified = JSON.stringify(shrunkEvents)

//   // console.debug('Pushing payload to kinesis', payload, jsonified.length) // eslint-disable-line no-console

//   // Break it up into chunks if it is too big
//   if (jsonified.length > MAX_CHUNK_SIZE) {
//     // const nChunks = Math.ceil(jsonified.length / MAX_CHUNK_SIZE)
//     // console.debug(
//     //   'Payload will be chunked, too big',
//     //           `${jsonified.length} > ${MAX_CHUNK_SIZE}`,
//     //           `nChunks=${nChunks}`,
//     // )
//     const records = []
//     // const recordPromisesMigrate = []
//     for (let i = 0; i < jsonified.length; i += MAX_CHUNK_SIZE) {
//       // const orderKey = getLastOrderKey()
//       const chunk = jsonified.slice(i, i + MAX_CHUNK_SIZE)
//       records.push(
//         getRequestEntry(pushArgs, generateOrderKey, chunk), /* .then(results =>
//           // console.debug('Got results from chunked push', results),
//         ), */
//       )
//       // recordPromisesMigrate.push(
//       //   getRequestEntry(orderKey, chunk, tokenPromiseMigrate),
//       // )
//     }
//     flushRecords(records)
//     // Promise.all(recordPromisesMigrate).then(flushRecordsMigrate)
//   }
//   // Otherwise, just push it
//   else {
//     // const orderKey = getLastOrderKey()
//     flushRecord(getRequestEntry(pushArgs, generateOrderKey, jsonified))
//     // getRequestEntry(orderKey, jsonified, tokenPromiseMigrate).then(flushRecordMigrate)
//   }
// }

export const queue = new PushQueue('recordingEvents', async (pushArgs: PushArguments, events: RecordingEventByActionType[]) => {
  try {
    const startTimestamp = events[0].timestamp
    const endTimestamp = events[events.length - 1].timestamp
    const shrunkEvents = shrink(events)

    const jsonified = JSON.stringify(shrunkEvents)

    // console.debug('Pushing payload to kinesis', payload, jsonified.length) // eslint-disable-line no-console

    // Break it up into chunks if it is too big
    if (jsonified.length > MAX_CHUNK_SIZE) {
      // const nChunks = Math.ceil(jsonified.length / MAX_CHUNK_SIZE)
      // console.debug(
      //   'Payload will be chunked, too big',
      //           `${jsonified.length} > ${MAX_CHUNK_SIZE}`,
      //           `nChunks=${nChunks}`,
      // )
      const records = []
      // const recordPromisesMigrate = []
      for (let i = 0; i < jsonified.length; i += MAX_CHUNK_SIZE) {
        // const orderKey = getLastOrderKey()
        const chunk = jsonified.slice(i, i + MAX_CHUNK_SIZE)
        records.push(
          getRequestEntry(pushArgs, startTimestamp, endTimestamp, recordingEventsCounter.increment, chunk), /* .then(results =>
            // console.debug('Got results from chunked push', results),
          ), */
        )
        // recordPromisesMigrate.push(
        //   getRequestEntry(orderKey, chunk, tokenPromiseMigrate),
        // )
      }
      const res = await flushRecords(records)
      if ((res.$metadata.httpStatusCode !== 200 && res.$metadata.httpStatusCode !== 201) || res.FailedRecordCount) { // stability: need to check if these are the only possible success codes
        // stability: need to ensure this does not result in poison pill infinite loop behavior
        return { success: false }
      }
      // Promise.all(recordPromisesMigrate).then(flushRecordsMigrate)
    }
    // Otherwise, just push it
    else {
      // const orderKey = getLastOrderKey()
      const res = await flushRecord(getRequestEntry(pushArgs, startTimestamp, endTimestamp, recordingEventsCounter.increment, jsonified))
      if (res.$metadata.httpStatusCode !== 200 && res.$metadata.httpStatusCode !== 201) { // stability: need to check if these are the only possible success codes
        return { success: false }
      }
      // getRequestEntry(orderKey, jsonified, tokenPromiseMigrate).then(flushRecordMigrate)
    }
  }
  catch (_) {
    return { success: false }
  }

  return { success: true }
}, (x): x is RecordingEventByActionType[] => Array.isArray(x))

export const push = queue.push.bind(queue)

// This is a (probably unnecessary) wrapper around the previous function
// that is more specific in the types it accepts
// function pushRecordingEvents(events: RecordingEventByActionType[]) {
//   // dummy function for now
//   if (events.length === 0)
//     return

//   pushPayloadToKinesis(shrink(events))
//   if (config.token) {
//     recordLastVisit(config.token)
//   }
// }

// TODO: make sure record last visit is called somewhere
