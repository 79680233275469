import { from, fromEvent, map, merge, mergeMap } from 'rxjs'
import { getElementWaiter } from '../../elementWaiter'

export interface CodelessEventListener {
  name: string
  pk: string
  sk: string
  organization: string
  site: string
  url: string
  selectors: {
    name: string
    selector: string
    events: Set<'click' | 'load' | 'mouseover'>
  }[]
  description: string
  status: 'active' | 'inactive'
  startDate: string
  endDate?: string | undefined
}

export interface CodelessEvent {
  sessionId: string
  pk: string
  sk: string
  type: string
  selector: string
  timestamp: number
}

// Very basic, just listens to events on the page and emits them
// The events are given by the metrics endpoint
export function createCodelessEventsObservable(eventListeners: CodelessEventListener[], sessionId: string) {
  const observables = []

  for (const { selectors, sk, pk } of eventListeners) {
    for (const { selector, events } of selectors) {
      for (const type of events) {
        const mapToCodelessEvent = map((): CodelessEvent => ({ sessionId, pk, sk, type, selector, timestamp: Date.now() }))

        if (selector === 'window') {
          let windowEventListener$ = fromEvent(window, type)
          if (type === 'load') {
            windowEventListener$ = merge(windowEventListener$, from(new Promise<Event>((resolve) => {
              if (document.readyState === 'complete')
                resolve(new Event('load'))
            })))
          }
          observables.push(windowEventListener$.pipe(
            mapToCodelessEvent,
          ))
        }
        else {
          const elementObservable = getElementWaiter().waitFor(selector).pipe(
            mergeMap(($element) => {
              // console.log('Found element', $element, 'for selector', selector, 'and type', type)
              const eventListener$ = fromEvent($element, type)

              return eventListener$.pipe(
                mapToCodelessEvent,
              )
            }),
          )

          observables.push(elementObservable)
        }
      }
    }
  }
  // console.log('Observables here', observables)
  const allEvents$ = merge(...observables)
  // console.log('All events observable', allEvents$)

  return allEvents$
}
