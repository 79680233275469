import { SESSION_TIMEOUT_MS } from '@/const'
import { generate } from 'xksuid'
import { logger } from './logging'

export type SortableId = `${string}#${string}` // a push ID is a combination of the timestamp as an ISO-encoded date and an order index key
// This ensures that it is sortable and unique

export function makeSortableId(t: number, index: number | string): SortableId {
  return `${new Date(t).toISOString()}#${index}`
}

export function getSessionId(key: string, lastVisit?: Date) {
  logger.debug('getSessionId', key, lastVisit)
  const sessionIdLocalStorageKey = `_n_id_${key}`
  const savedSessionID = localStorage.getItem(sessionIdLocalStorageKey)
  logger.debug('savedSessionID', savedSessionID)

  const isNewSession
    = !savedSessionID || checkNewSession(lastVisit)

  logger.debug('isNewSession', isNewSession)

  if (!isNewSession)
    return savedSessionID

  const sessionId = `session#${generate()}`
  // Reset session tracker
  localStorage.setItem(sessionIdLocalStorageKey, sessionId)
  return sessionId
}

function checkNewSession(lastVisit: Date | undefined) {
  // Simple check to break the session if the user has been away for a while
  return !lastVisit
    || new Date().valueOf() - lastVisit.valueOf() > SESSION_TIMEOUT_MS
}

function parsePageId(pageId: string) {
  const [pageStartTimestamp, pageIndex] = pageId.split('#')
  return {
    pageStartTimestamp,
    pageIndex: Number.parseInt(pageIndex),
  }
}

export function getPageId(t: number, sessionId: string, key: string) {
  const pageIdLocalStorageKey = `_n_page_id_${key}_${sessionId}`
  const savedPageId = localStorage.getItem(pageIdLocalStorageKey)
  const isNewPage = !savedPageId

  const pageIndex = isNewPage ? 0 : parsePageId(savedPageId).pageIndex + 1

  const pageId = makeSortableId(t, pageIndex)
  localStorage.setItem(pageIdLocalStorageKey, pageId)
  return pageId
}

export function getVisitorID() {
  let visitorId = document.cookie
    .split('; ')
    .find(row => row.startsWith('engine-visitor-id'))
    ?.split('=')[1]
  if (!visitorId) {
    visitorId = generate()
    document.cookie = `engine-visitor-id=${visitorId};max-age=31536000`
  }
  return visitorId
}
