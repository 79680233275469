import type { RecordingEventByActionType } from '@/types/recorder'
import type { DOMSerializer } from '@/util/domSerializer'
import { ActionType } from '@/types/recorder'
import { Observable } from 'rxjs'

type _CSSStyleSheet = typeof CSSStyleSheet

/**
 * Given a style element, returns the differences between the rules
 * in its live CSSStyleSheet and those parsed from its text.
 *
 * @param style A HTMLStyleElement in the document.
 * @param document The Document object.
 * @returns An object with two arrays:
 *   - newRules: rule texts that are in the parsed (virtual) sheet but not in the live sheet.
 *   - rulesToRemove: indexes of rules in the live sheet that are not in the parsed (virtual) sheet.
 */
function getCssRuleDifferences(
  style: HTMLStyleElement,
  document: Document,
): { rulesToAdd: string[], rulesToRemove: number[] } {
  const sheet = style.sheet
  if (!sheet)
    return { rulesToAdd: [], rulesToRemove: [] }

  // Create a virtual style element from the style's text.
  const vStyle = document.createElement('style')
  vStyle.innerHTML = style.innerHTML
  document.head.appendChild(vStyle)

  const vSheet = vStyle.sheet

  // Build a set of rule texts from the virtual (parsed) sheet.
  const virtualSet = new Set<string>()
  if (vSheet && vSheet.cssRules) {
    for (let i = 0; i < vSheet.cssRules.length; i++) {
      virtualSet.add(vSheet.cssRules[i].cssText)
    }
  }

  // For the live sheet, record indexes of rules missing in the virtual set.
  const rulesToAdd: string[] = []
  const liveSet = new Set<string>()
  for (let i = 0; i < sheet.cssRules.length; i++) {
    const ruleText = sheet.cssRules[i].cssText
    liveSet.add(ruleText)
    if (!virtualSet.has(ruleText)) {
      rulesToAdd.push(ruleText)
    }
  }

  // For rules to remove, find rule texts in the virtual sheet missing in the live sheet.
  const rulesToRemove: number[] = []
  if (vSheet && vSheet.cssRules) {
    for (let i = 0; i < vSheet.cssRules.length; i++) {
      const ruleText = vSheet.cssRules[i].cssText
      if (!liveSet.has(ruleText)) {
        rulesToRemove.push(i)
      }
    }
  }

  document.head.removeChild(vStyle)
  return { rulesToAdd, rulesToRemove }
}

export function createCSSOMObservable({
  window,
  document,
  domSerializer,
}: {
  domSerializer: DOMSerializer
  window: Window
  document: Document
}) {
  const methods = ['insertRule', 'deleteRule', 'replace', 'replaceSync'] as const
  const originals: Record<string, any> = {}

  const CSSStyleSheet = (window as any).CSSStyleSheet as _CSSStyleSheet

  return new Observable<Omit<RecordingEventByActionType<ActionType.CSS_EVENT>, 'timestamp'>>((subscriber) => {
    // For every style element, compute the differences between its CSS rules
    // in the live sheet and those derived from its text.
    document.querySelectorAll('style').forEach((node) => {
      if (!(node instanceof HTMLStyleElement))
        return
      const sheet = node.sheet
      if (!(sheet instanceof CSSStyleSheet))
        return

      const { rulesToAdd, rulesToRemove } = getCssRuleDifferences(node, document)

      // Emit an 'insertRule' event for rules that are in the live sheet
      // but missing from the text.
      rulesToAdd.forEach((ruleText) => {
        subscriber.next({
          type: ActionType.CSS_EVENT,
          data: {
            method: 'insertRule',
            sheet: domSerializer.getNodeId(node),
            args: [ruleText],
          },
        })
      })

      // Emit a 'deleteRule' event for rules that are in the text
      // but missing from the live sheet.
      rulesToRemove.forEach((index) => {
        subscriber.next({
          type: ActionType.CSS_EVENT,
          data: {
            method: 'deleteRule',
            sheet: domSerializer.getNodeId(node),
            args: [index],
          },
        })
      })
    })

    console.log('Init once')

    // Override the CSSStyleSheet methods to watch for mutations.
    methods.forEach((method) => {
      if (typeof CSSStyleSheet.prototype[method] !== 'function')
        return

      console.log('Hijacking', method)

      // Save the original method.
      originals[method] = CSSStyleSheet.prototype[method]

      const originalMethod = originals[method] // create bound function

      // Override with our own.
      CSSStyleSheet.prototype[method] = function (...args: Parameters<CSSStyleSheet[typeof method]>) {
        if (this.ownerNode) {
          subscriber.next({
            type: ActionType.CSS_EVENT,
            data: {
              method,
              sheet: domSerializer.getNodeId(this.ownerNode),
              args: args as any, // sadly ts cannot infer this greatness
            },
          })
        }
        return originalMethod.apply(this, args)
      }
    })

    // Cleanup: restore original methods.
    return () => {
      methods.forEach((method) => {
        if (originals[method]) {
          CSSStyleSheet.prototype[method] = originals[method]
        }
      })
    }
  })
}
