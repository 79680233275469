import type { SerializedMutation, SerializedNode } from '@/util/domSerializer'
import type { Observable } from 'rxjs'

export interface RecorderObservable {
  observable: Observable<RecordingEventByActionType[]>
}

export enum ActionType {
  UNKNOWN = 0, // 'UNKNOWN',
  SCREEN_EVENT = 1, // 'SCREEN_EVENT',
  MOUSE_EVENT = 2, // 'MOUSE_EVENT',
  TOUCH_EVENT = 3, // 'TOUCH_EVENT',
  KEYBOARD_EVENT = 4, // 'KEYBOARD_EVENT',
  TEXT_INPUT_EVENT = 5, // 'TEXT_INPUT_EVENT',
  SCROLL_EVENT = 6, // 'SCROLL_EVENT',
  MUTATION_EVENT = 7, // 'MUTATION_EVENT',
  VISIBILITY_EVENT = 8, // 'VISIBILITY_EVENT',
  BEFOREUNLOAD_EVENT = 9, // 'BEFOREUNLOAD_EVENT', // this is janky and should probably not have its own shebang
  CSS_EVENT = 10, // 'CSS_EVENT',
}

export interface SavedStyle {
  hash: string
  content?: string | null
  url?: string | null
}
export interface RecordingSetup {
  timestamp: number
  styles: SavedStyle[] // In the order they are found in the document
  dom: SerializedNode
  url: string // The URL of the page being recorded
  referrer: string
  screen: {
    width: number
    height: number
  }
  browser: {
    userAgent: string
    [key: string]: any
  }
  loadTimings: PerformanceEntry
  activeEngines?: {
    metricId: string
    metricType: 'event' | 'integration'
    engineSk: string
    chosenTest: string
  }[]
}

export interface RecordingState {
  setup: RecordingSetup
  events: RecordingEventByAnyActionType[]
  isRecording: boolean
}

export interface RecordingEvent {
  timestamp: number
  type: ActionType
}

export type AnyActionType = keyof RecordingEventDataMap
export type AnyRecordingEventData = RecordingEventDataMap[AnyActionType]

export type RecordingEventByActionType<T extends AnyActionType = AnyActionType> = {
  [K in keyof RecordingEventDataMap]: {
    type: K
    timestamp: number
    data: RecordingEventDataMap[K]
  }
}[T]

export interface RecordingEventData<T extends AnyActionType = AnyActionType> {
  type: T
  data: RecordingEventDataMap[T]
}

export type RecordingVisibilityEventData = 'hidden' | 'visible'

export interface RecordingScreenEventData {
  width: number
  height: number
}

export interface RecordingMouseEventData {
  x: number
  y: number
  click: boolean
  leave: boolean
}

export interface RecordingTouchEventData {
  touches: { x: number, y: number, id: number }[]
}

export interface RecordingKeyboardEventData {
  key: string
}

export interface RecordingElementEventData {
  target: number
}

export interface RecordingTextInputEventData extends RecordingElementEventData {
  value: string
}

export interface RecordingScrollEventData extends Omit<RecordingElementEventData, 'target'> {
  target: number | null
  x: number
  y: number
}

export type RecordingCSSEventData = {
  method: 'insertRule'
  sheet: number
  args: [rule: string, index: number] | [rule: string]
} | {
  method: 'deleteRule'
  sheet: number
  args: [index: number]
} | {
  method: 'replace'
  sheet: number
  args: [text: string]
} | {
  method: 'replaceSync'
  sheet: number
  args: [text: string]
}

// export interface RecordingMutationEventData extends RecordingElementEventData {
//   mutationType: 'attributes' | 'characterData' | 'childList'
//   attributeName?: string // only if mutationtype == "attributes"
//   value: string // inner HTML or new attribute value
// }

export type RecordingMutationEventData = SerializedMutation

export interface RecordingEventDataMap {
  [ActionType.SCREEN_EVENT]: RecordingScreenEventData
  [ActionType.MOUSE_EVENT]: RecordingMouseEventData
  [ActionType.TOUCH_EVENT]: RecordingTouchEventData
  [ActionType.KEYBOARD_EVENT]: RecordingKeyboardEventData
  [ActionType.TEXT_INPUT_EVENT]: RecordingTextInputEventData
  [ActionType.SCROLL_EVENT]: RecordingScrollEventData
  [ActionType.MUTATION_EVENT]: RecordingMutationEventData
  [ActionType.VISIBILITY_EVENT]: RecordingVisibilityEventData
  [ActionType.BEFOREUNLOAD_EVENT]: RecordingVisibilityEventData
  [ActionType.CSS_EVENT]: RecordingCSSEventData
}

export type RecordingEventByAnyActionType =
  | RecordingEventByActionType<ActionType.SCREEN_EVENT>
  | RecordingEventByActionType<ActionType.MOUSE_EVENT>
  | RecordingEventByActionType<ActionType.TOUCH_EVENT>
  | RecordingEventByActionType<ActionType.KEYBOARD_EVENT>
  | RecordingEventByActionType<ActionType.TEXT_INPUT_EVENT>
  | RecordingEventByActionType<ActionType.SCROLL_EVENT>
  | RecordingEventByActionType<ActionType.MUTATION_EVENT>
  | RecordingEventByActionType<ActionType.BEFOREUNLOAD_EVENT>
  | RecordingEventByActionType<ActionType.VISIBILITY_EVENT>
  | RecordingEventByActionType<ActionType.CSS_EVENT>

export type AllEventMaps = (WindowEventMap & DocumentEventMap & HTMLElementEventMap)
export type EventName = keyof AllEventMaps
