import type { CodelessEvent } from './events'
import { logger } from '@/util/logging'
import { map } from 'rxjs'
import { getElementWaiter } from '../../elementWaiter'

interface EngineEdit {
  name: string
  type: 'image' | 'text' | 'image.srcset' | 'style'
  selector: string
  value: string
}

interface EngineTest {
  name: string
  metric: number
  control: boolean
  views: number
  edits: EngineEdit[]
}

export interface Engine {
  name: string
  pk: string
  sk: string
  organization: string
  site: string
  url: string
  startDate: string
  status: 'active' | 'stopped'
  description: string
  metric: {
    type: 'codelessEvent'
    id: string
  }
  tests: EngineTest[]
  created: string
  modified: string
  endDate?: string | undefined
}

const IMAGE_UPLOAD_BUCKET_NAME = import.meta.env.IMAGE_UPLOAD_BUCKET_NAME || import.meta.env.VITE_IMAGE_UPLOAD_BUCKET_NAME

// Only count when the engine's page is visited
export interface ActiveEngine { // stupid naming but idk what else to call this hahaha
  sessionId: string
  metricId: string
  metricType: 'codelessEvent'
  engineSk: string
  chosenTest: string
  expiresAt: number
}

// export const engineGlobals: { activeEngines: ActiveEngine[] } = {
//   activeEngines: [],
// }

export interface EngineCodelessEvent extends CodelessEvent {
  chosenTests?: { engineSk: string, chosenTest: string }[]
}

// This is set for more than A and B but usually we'll just have A and B
function chooseTest({ tests }: Engine) {
  const id = Math.floor(Math.random() * tests.length)
  // const visitorId = makeVisitorID()
  const test = tests[id]
  return test
}

// function cookieSafeBtoa(s: string) {
//   return btoa(s).replaceAll('=', '$')
// }
// function cookieSafeAtob(s: string) {
//   return atob(s.replaceAll('$', '='))
// }

// Initialize the engine for the current session
// function makeEngineTest(a: any, b: any) {
//   return {
//     data: {
//       eventListeners: [{
//         organization: 'organization#2i8YiXplDslHGMjHYVYdlt8n9xF',
//         site: 'site#2ZWsHTwNYUA2kffbdc3uP5V7Fu3',
//         url: 'http://localhost:5173/',
//         name: 'Two-Toned Landing Click',
//         selectors: [{ selector: 'img', name: 'Default click', events: ['click'] }],
//         description: '',
//         status: 'active',
//         startDate: '2024-05-01T01:22:47.070Z',
//         pk: '/organization#2i8YiXplDslHGMjHYVYdlt8n9xF/site#2ZWsHTwNYUA2kffbdc3uP5V7Fu3',
//         sk: 'eventListener#active#http://localhost:5173/#2fquXKLtiE9JLfn3NGaZ8hcAukP',
//         created: '2024-05-01T01:22:47.070Z',
//         modified: '2024-11-18T19:49:36.060Z',
//       }],
//       engines: [{
//         organization: 'organization#2i8YiXplDslHGMjHYVYdlt8n9xF',
//         site: 'site#2ZWsHTwNYUA2kffbdc3uP5V7Fu3',
//         url: 'http://localhost:5173/',
//         startDate: '2024-11-23',
//         endDate: '',
//         status: 'active',
//         name: 'asfdasfas',
//         description: 'asfasfasf',
//         metric: { type: 'codelessEvent', id: '/organization#2i8YiXplDslHGMjHYVYdlt8n9xF/site#2ZWsHTwNYUA2kffbdc3uP5V7Fu3/eventListener#active#http://localhost:5173/#2fquXKLtiE9JLfn3NGaZ8hcAukP' },
//         tests: [
//           { name: 'B', control: false, views: 0, metric: 0, edits: [...(Array.isArray(b) ? b : [b])] },
//           { name: 'A', control: true, views: 0, metric: 0, edits: [...(Array.isArray(a) ? a : [a])] },
//         ],
//         pk: '/organization#2i8YiXplDslHGMjHYVYdlt8n9xF/site#2ZWsHTwNYUA2kffbdc3uP5V7Fu3',
//         sk: 'engine#active#http://localhost:5173#2pEtO8sKITmotF0WAV6wOmAYm2j',
//         created: '2024-11-23T06:23:47.024Z',
//         modified: '2024-11-23T06:23:47.024Z',
//       }],
//     },
//   }
// }

export function initEngine(sessionId: string, engines: Engine[]) {
  // const a = 'color:gray'
  // const b = 'color: red; background-color: yellow;'

  // engines = makeEngineTest({ type: 'style', selector: 'h1', name: 'Style H1', value: a }, { type: 'style', selector: 'h1', name: 'Style H1', value: b }).data.engines
  // const visitorId = makeVisitorID()

  // TODO: make this less WET
  // const savedActiveEngines = window.document.cookie.split('; ').filter(row => row.startsWith('engine-st-')).map(row => row.split('=')).map(([key, base64]) => {
  const savedActiveEngines = Object.entries(localStorage).filter(([key]) => key.startsWith('engine-st-')).map(([key, value]) => {
    const parsed = JSON.parse(value)
    if (!parsed.expiresAt || parsed.expiresAt < Date.now()) {
      localStorage.removeItem(key)
      return null
    }

    const engineSessionId = key.split('-')[2]
    const activeEngine: ActiveEngine = { sessionId: engineSessionId, ...JSON.parse(value) }
    // if (metricType === 'event' && sessionId === engineSessionId) { // todo: implement for visitors as well
    //   // codelessEventEngineTestMap[metricId] ??= new Set()
    //   // codelessEventEngineTestMap[metricId].add({ engineSk, chosenTest })

    return activeEngine
  }).filter((x): x is ActiveEngine => x !== null)

  // Loop over all the engines that are not stopped and apply them
  const activeEngines = savedActiveEngines

  // const localStorageEntry = {}
  for (const engine of engines.filter(engine => engine.status !== 'stopped')) {
    const existingChosenTestName = savedActiveEngines.find(x => x.engineSk === engine.sk)?.chosenTest
    const existingTest = existingChosenTestName !== undefined && engine.tests.find(test => test.name === existingChosenTestName)
    const test = existingTest || chooseTest(engine)

    // if (!existingTest) {
    // }
    const { sk, metric } = engine
    const chosenTest: ActiveEngine = { metricId: metric.id, metricType: metric.type, engineSk: sk, chosenTest: test.name, sessionId, expiresAt: Date.now() + 1200 * 1000 } // the engine expires after this time
    // window.document.cookie = `engine-st-${sessionId}-${cookieSafeBtoa(engineSk)}=${btoa(JSON.stringify(chosenTest))};max-age=1200` // the engine expires after this time
    localStorage.setItem(`engine-st-${sessionId}-${sk}`, JSON.stringify(chosenTest))

    activeEngines.push(chosenTest)
    logger.debug('Chose test', test)

    if (test.control)
      continue
    for (const edit of test.edits) {
      getElementWaiter().waitFor(edit.selector).subscribe(($element) => {
        // console.log('Found element', $element, 'for selector', edit.selector, 'and type', edit.type)
        switch (edit.type) {
          case 'text':
            $element.textContent = edit.value
            break
          case 'image': {
            if (/^https?:\/\//.test(edit.value)) {
              $element.setAttribute('src', edit.value)
              break
            }
            const bucketUrl = `https://${IMAGE_UPLOAD_BUCKET_NAME}.s3.amazonaws.com/${edit.value.split('/').map(encodeURIComponent).join('/')}`
            $element.setAttribute('src', bucketUrl)
            break
          }
          case 'image.srcset': {
            if (/^https?:\/\//.test(edit.value)) {
              $element.setAttribute('srcset', edit.value)
              break
            }
            const bucketUrl = `https://${IMAGE_UPLOAD_BUCKET_NAME}.s3.amazonaws.com/${edit.value.split('/').map(encodeURIComponent).join('/')}`
            $element.setAttribute('srcset', bucketUrl)
            break
          }
          case 'style':
            $element.setAttribute('style', edit.value)
            break
        }
      })
    }
  }

  return activeEngines
}

// This nasty little function is a transformer for codeless events that adds engine information to them
export function createEngineMapper(sessionId: string, activeEngines: ActiveEngine[] | undefined) {
  // Loop over every engine cookie
  // const engineCookies = window.document.cookie.split('; ').filter(row => row.startsWith('engine-st-')).map(row => row.split('='))
  if (!activeEngines)
    return map((event: CodelessEvent): EngineCodelessEvent => event) // identity map

  const codelessEventEngineTestMap: Record<string, Set<{ engineSk: string, chosenTest: string }>> = {}
  // for (const [key, base64] of engineCookies) {
  //   const engineSessionId = key.split('-')[2]
  //   const { engineSk, metricId, metricType, chosenTest } = JSON.parse(atob(base64))
  //   if (metricType === 'event' && sessionId === engineSessionId) { // todo: implement for visitors as well. we only allow this within one session
  //     codelessEventEngineTestMap[metricId] ??= new Set()
  //     codelessEventEngineTestMap[metricId].add({ engineSk, chosenTest })
  //   }
  // }

  logger.debug('active engines', activeEngines)

  for (const activeEngine of activeEngines) {
    const { engineSk, metricId, metricType, chosenTest, sessionId: engineSessionId } = activeEngine
    if (metricType === 'codelessEvent' && sessionId === engineSessionId) { // todo: implement for visitors as well. we only allow this within one session
      codelessEventEngineTestMap[metricId] ??= new Set()
      codelessEventEngineTestMap[metricId].add({ engineSk, chosenTest })
    }
  }
  return map((event: CodelessEvent): EngineCodelessEvent => {
    const chosenTests = codelessEventEngineTestMap[`${event.pk}/${event.sk}`]
    if (chosenTests)
      return { ...event, chosenTests: [...chosenTests] }
    return event
  })
}
