import type {
  AllEventMaps,
  AnyActionType,
  EventName,
  RecordingEventByActionType,
  RecordingEventDataMap,
} from '@/types/recorder'

import type { DOMSerializer } from '@/util/domSerializer'
// import { createHash } from 'node:crypto' // WARNING: STANDARD LIB FROM NODE IMPORT. HERE BECAUSE OTHER LIBS (AB)USE IT
import type { OperatorFunction } from 'rxjs'

import type { EventListenerOptions } from 'rxjs/internal/observable/fromEvent'
import { ActionType } from '@/types/recorder'
import { fromEvent, map, pipe, throttleTime } from 'rxjs'
import { simplifyPointsWithTimeout } from '../helpers'

function createEventObservable<U extends AnyActionType, K extends EventName>(
  targets: EventTarget[],
  eventName: K,
  type: U,
  operator: OperatorFunction<AllEventMaps[K], RecordingEventDataMap[U]>,
  listenerOptions?: EventListenerOptions,
) {
  const mergedListenerOptions = { passive: true, ...(listenerOptions || {}) } // merge with defaults
  // We take the event, pipe it through the operator, and return the resultant data along with the type of event
  return fromEvent<AllEventMaps[K]>(targets, eventName, mergedListenerOptions)
    .pipe(operator, map<RecordingEventDataMap[U], Pick<RecordingEventByActionType<U>, 'data' | 'type'>>(data => ({ data, type })))
}

export function createDOMEventObservables({ window, document, domSerializer }: { window: Window, document: Document, domSerializer: DOMSerializer }) {
  // This is how everything is recorded except for mutation observers which don't fit the event listener paradigm very well
  return [
    // Window events
    createEventObservable(
      [window],
      'resize',
      ActionType.SCREEN_EVENT,
      map(() => ({
        width: window.innerWidth,
        height: window.innerHeight,
      })),
    ),

    // Scroll events on window and elements
    createEventObservable(
      [document],
      'scroll',
      ActionType.SCROLL_EVENT,
      pipe(
        throttleTime(100, undefined, { leading: true, trailing: true }),
        map(({ target }) => {
          if (target instanceof Element)
            return { target: domSerializer.getNodeId(target), x: target.scrollLeft, y: target.scrollTop }

          else
            return { target: null, x: window.scrollX, y: window.scrollY }
        }),
      ),
      { capture: true },
    ),

    // Keyboard events
    // Comment reason: currently ignoring input events for security
    // createEventObservable(
    //   [window],
    //   'keydown',
    //   ActionType.KEYBOARD_EVENT,
    //   map(({ key }) => ({ key })), // just the key
    // ),
    // createEventObservable(
    //   [...document.querySelectorAll('textarea, input')], // TODO: support select elements, etc
    //   'input',
    //   ActionType.TEXT_INPUT_EVENT,
    //   pipe(
    //     pairwise(),
    //     filter(([prev, curr]) => (
    //       curr.target instanceof HTMLInputElement || curr.target instanceof HTMLTextAreaElement
    //     ) && (!prev || (!(prev.target instanceof HTMLInputElement) || prev.target.value !== curr.target.value))),
    //     map(([_, curr]) => ({
    //       value: (curr.target as HTMLInputElement | HTMLTextAreaElement).value,
    //       target: domSerializer.getNodeId(curr.target as (HTMLInputElement | HTMLTextAreaElement)),
    //     })),
    //   ),
    // ),

    // Mouse events
    createEventObservable(
      [window],
      'mousemove',
      ActionType.MOUSE_EVENT,
      pipe(simplifyPointsWithTimeout(500), map(({ x, y }) => ({ x, y, click: false, leave: false }))),
    ),
    createEventObservable(
      [window],
      'mouseout',
      ActionType.MOUSE_EVENT,
      map(({ x, y }) => ({ x, y, click: false, leave: true })),
    ),
    createEventObservable(
      [window],
      'click',
      ActionType.MOUSE_EVENT,
      map(({ x, y }) => ({ x, y, click: true, leave: false })),
    ),

    // Touch events
    createEventObservable(
      [window],
      'touchstart',
      ActionType.TOUCH_EVENT,
      pipe(
        map((e: TouchEvent) => ({
          touches: [...e.touches].map(({ clientX: x, clientY: y, identifier: id }: Touch) => ({ x, y, id, type: 'start' })),
        })),
      ),
    ),
    createEventObservable(
      [window],
      'touchmove',
      ActionType.TOUCH_EVENT,
      pipe(
        throttleTime(66.67),
        map((e: TouchEvent) => ({
          touches: [...e.touches].map(({ clientX: x, clientY: y, identifier: id }: Touch) => ({ x, y, id })), // TODO: add condition for throttle/debounce based on distance. This needs a bit of involvement with untangling the multiple streams of touches
        })),
      ),
    ),
    createEventObservable(
      [window],
      'touchend',
      ActionType.TOUCH_EVENT,
      pipe(
        map((e: TouchEvent) => ({
          touches: [...e.touches].map(({ clientX: x, clientY: y, identifier: id }: Touch) => ({ x, y, id, type: 'end' })),
        })),
      ),
    ),

    // Visibility events
    createEventObservable([document], 'visibilitychange', ActionType.VISIBILITY_EVENT, map(() => document.visibilityState)),
    createEventObservable([window], 'blur', ActionType.VISIBILITY_EVENT, map(() => 'hidden')),
    createEventObservable([window], 'focus', ActionType.VISIBILITY_EVENT, map(() => 'visible')),
    createEventObservable([window], 'beforeunload', ActionType.VISIBILITY_EVENT, map(() => 'hidden')),
  ] as const
}
