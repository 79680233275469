import { map } from 'rxjs'
import { elementWaiter } from './elementWaiter'
import type { CodelessEvent } from './events'

interface EngineEdit {
  name: string
  selector: string
  type: 'text' | 'image'
  value: string
}

interface EngineTest {
  name: string
  id: string
  edits: EngineEdit[]
  control: boolean
  metric: number
  count: number
}

interface Engine {
  sitePk: string
  engineSk: string
  name: string
  pageUrl: string
  tests: EngineTest[]
  metricId: string
  metricType: 'event' | 'integration'
  status?: 'running' | 'stopped'
}

const IMAGE_UPLOAD_BUCKET_NAME = import.meta.env.IMAGE_UPLOAD_BUCKET_NAME || 'nlytics-dev-uploads-storage-bucket'

// Only count when the engine's page is visited
export interface ActiveEngine { // stupid naming but idk what else to call this hahaha
  sessionId: string
  metricId: string
  metricType: 'event' | 'integration'
  engineSk: string
  chosenTest: string
}

export const engineGlobals: { activeEngines: ActiveEngine[] } = {
  activeEngines: [],
}

// This is set for more than A and B but usually we'll just have A and B
function chooseTest(window: Window, { tests }: Engine) {
  // const visitorId = makeVisitorID()
  const test = tests[Math.floor(Math.random() * tests.length)]
  return test
}

// function cookieSafeBtoa(s: string) {
//   return btoa(s).replaceAll('=', '$')
// }
// function cookieSafeAtob(s: string) {
//   return atob(s.replaceAll('$', '='))
// }

// Initialize the engine for the current session
export function initEngine(window: Window, sessionId: string, engines: Engine[]) {
  // const visitorId = makeVisitorID()

  // TODO: make this less WET
  // const savedActiveEngines = window.document.cookie.split('; ').filter(row => row.startsWith('engine-st-')).map(row => row.split('=')).map(([key, base64]) => {
  const savedActiveEngines = Object.entries(localStorage).filter(([key]) => key.startsWith('engine-st-')).map(([key, value]) => {
    const engineSessionId = key.split('-')[2]
    const activeEngine: ActiveEngine = { sessionId: engineSessionId, ...JSON.parse(value) }
    // if (metricType === 'event' && sessionId === engineSessionId) { // todo: implement for visitors as well
    //   // codelessEventEngineTestMap[metricId] ??= new Set()
    //   // codelessEventEngineTestMap[metricId].add({ engineSk, chosenTest })

    return activeEngine
  })

  // Loop over all the engines that are not stopped and apply them
  engineGlobals.activeEngines = savedActiveEngines

  // const localStorageEntry = {}
  for (const engine of engines.filter(engine => engine.status !== 'stopped')) {
    const test = chooseTest(window, engine)

    const { engineSk, metricId, metricType } = engine
    const chosenTest = { metricId, metricType, engineSk, chosenTest: test.id, sessionId }
    // window.document.cookie = `engine-st-${sessionId}-${cookieSafeBtoa(engineSk)}=${btoa(JSON.stringify(chosenTest))};max-age=1200` // the engine expires after this time
    localStorage.setItem(`engine-st-${sessionId}-${engineSk}`, JSON.stringify(chosenTest))

    engineGlobals.activeEngines.push(chosenTest)
    // console.log('Chose test', test)

    if (test.control)
      continue
    for (const edit of test.edits) {
      elementWaiter.waitFor(edit.selector).subscribe(($element) => {
        // console.log('Found element', $element, 'for selector', edit.selector, 'and type', edit.type)
        switch (edit.type) {
          case 'text':
            $element.textContent = edit.value
            break
          case 'image': {
            const bucketUrl = `https://${IMAGE_UPLOAD_BUCKET_NAME}.s3.amazonaws.com/${edit.value.split('/').map(encodeURIComponent).join('/')}`
            $element.setAttribute('src', bucketUrl)
            break
          }
        }
      })
    }
  }
}

// This nasty little function is a transformer for codeless events that adds engine information to them
export function createEngineMapper(window: Window, sessionId: string) {
  // Loop over every engine cookie
  // const engineCookies = window.document.cookie.split('; ').filter(row => row.startsWith('engine-st-')).map(row => row.split('='))
  const codelessEventEngineTestMap: Record<string, Set<{ engineSk: string, chosenTest: string }>> = {}
  // for (const [key, base64] of engineCookies) {
  //   const engineSessionId = key.split('-')[2]
  //   const { engineSk, metricId, metricType, chosenTest } = JSON.parse(atob(base64))
  //   if (metricType === 'event' && sessionId === engineSessionId) { // todo: implement for visitors as well. we only allow this within one session
  //     codelessEventEngineTestMap[metricId] ??= new Set()
  //     codelessEventEngineTestMap[metricId].add({ engineSk, chosenTest })
  //   }
  // }

  for (const activeEngine of engineGlobals.activeEngines) {
    const { engineSk, metricId, metricType, chosenTest, sessionId: engineSessionId } = activeEngine
    if (metricType === 'event' && sessionId === engineSessionId) { // todo: implement for visitors as well. we only allow this within one session
      codelessEventEngineTestMap[metricId] ??= new Set()
      codelessEventEngineTestMap[metricId].add({ engineSk, chosenTest })
    }
  }
  return map((event: CodelessEvent): CodelessEvent & { chosenTests?: { engineSk: string, chosenTest: string }[] } => {
    const chosenTests = codelessEventEngineTestMap[event.eventListenerSk]
    if (chosenTests)
      return { ...event, chosenTests: [...chosenTests] }
    return event
  })
}
