// import { createHash } from 'node:crypto' // WARNING: STANDARD LIB FROM NODE IMPORT. HERE BECAUSE OTHER LIBS (AB)USE IT
import type { OperatorFunction } from 'rxjs'
import { Observable, concatAll, fromEvent, interval, map, merge, of, pairwise, pipe, throttle, throttleTime } from 'rxjs'

import type { EventListenerOptions } from 'rxjs/internal/observable/fromEvent'
import { ActionType } from './types/recorder'
import type {
  AnyActionType,
  RecordingEventByActionType,
  RecordingEventDataMap,
  RecordingSetup,
} from './types/recorder'

import { DOMSerializer } from './util/domSerializer'
import { engineGlobals } from './engine'

type AllEventMaps = (WindowEventMap & DocumentEventMap & HTMLElementEventMap)
type EventName = keyof AllEventMaps

interface RecorderOptions<U extends AnyActionType, K extends EventName> {
  targets: EventTarget[]
  eventName: K
  type: U
  operator: OperatorFunction<AllEventMaps[K], RecordingEventDataMap[U]> // these will be added in later automatically
  listenerOptions?: EventListenerOptions
}

// Nice helpers
interface Point {
  x: number
  y: number
}
function distance(a: Point, b: Point) {
  const x = a.x - b.x
  const y = a.y - b.y
  return (x * x + y * y) ** 0.5
}

// Helper function to simplify point chains
// If points are close together in time and in space, we can simplify them
// This "throttles" incoming points more if they are close together
// We are willing to accept this lower resolution for points that are close together because nobody cares about these 1mm movements
function simplifyPointsWithTimeout<T extends Point>(maxTimeout: number): OperatorFunction<T, T> {
  return pipe(
    pairwise(),
    throttle(([prev, curr]) => {
      const d = distance(prev, curr)
      const timeout = Math.min(maxTimeout / d, maxTimeout)
      return interval(timeout)
    }),
    map(([_, curr]) => curr),
  )
}

// Helper function to make types pretty
function recOpts<U extends AnyActionType, K extends EventName>(
  targets: EventTarget[],
  eventName: K,
  type: U,
  operator: OperatorFunction<AllEventMaps[K], RecordingEventDataMap[U]>,
  listenerOptions?: EventListenerOptions,
) {
  return {
    targets,
    eventName,
    type,
    operator,
    listenerOptions,
  }
}

// VERY DANGEROUS HERE
// Would consider something that iterates (ugly) over stuff like { [E in EventName]: RecorderOptions<U, E> }[EventName] but that doesn't do the zipping of the other type
// So annoyingggdsjkfjgkjshgb
function createRecorder<U extends AnyActionType, K extends EventName>({ targets, eventName, type, operator, listenerOptions }: RecorderOptions<U, any>) {
  const mergedListenerOptions = { passive: true, ...(listenerOptions || {}) } // merge with defaults
  // We take the event, pipe it through the operator, and return the resultant data along with the type of event
  return fromEvent<AllEventMaps[K]>(targets, eventName, mergedListenerOptions)
    .pipe(operator, map<RecordingEventDataMap[U], Pick<RecordingEventByActionType<U>, 'data' | 'type'>>(data => ({ data, type })))
}

// Helper function to create an rxjs observable from a mutation observer
function fromMutation(element: HTMLElement, options?: MutationObserverInit): Observable<MutationRecord[]> {
  return new Observable((subscriber) => {
    const observer = new MutationObserver((mutationsList, _) => {
      subscriber.next(mutationsList)
    })
    observer.observe(element, options)
    return () => observer.disconnect()
  })
}

function getTimestamp() {
  return Date.now() // performance.now() /* - creationTimestamp */ + startTimestamp
}

// This is the main function that creates the observable that records everything
// It should be self-explanatory /s
export function createEverythingRecorderObservable(window: Window, document: Document, domSerializer: DOMSerializer = new DOMSerializer(), startTimestamp: number, { disableWatch = {} }: { disableWatch?: { [key in ActionType]?: boolean } } = {}) {
  // const creationTimestamp = performance.now()

  // This is how everything is recorded except for mutation observers which don't fit the event listener paradigm very well
  const recorders = [
    recOpts(
      [window],
      'resize',
      ActionType.SCREEN_EVENT,
      map(() => ({
        width: window.innerWidth,
        height: window.innerHeight,
      })),
    ),
    recOpts(
      [document],
      'scroll',
      ActionType.SCROLL_EVENT,
      pipe(
        throttleTime(100, undefined, { leading: true, trailing: true }),
        map(({ target }) => {
          if (target instanceof Element)
            return { target: domSerializer.getNodeId(target), x: target.scrollLeft, y: target.scrollTop }

          else
            return { target: null, x: window.scrollX, y: window.scrollY }
        }),
      ),
      { capture: true },
    ),
    // recOpts(
    //   [window],
    //   'keydown',
    //   ActionType.KEYBOARD_EVENT,
    //   map(({ key }) => ({ key })), // just the key
    // ),
    // recOpts(
    //   [...document.querySelectorAll('textarea, input')], // TODO: support select elements, etc
    //   'input',
    //   ActionType.TEXT_INPUT_EVENT,
    //   pipe(
    //     pairwise(),
    //     filter(([prev, curr]) => (
    //       curr.target instanceof HTMLInputElement || curr.target instanceof HTMLTextAreaElement
    //     ) && (!prev || (!(prev.target instanceof HTMLInputElement) || prev.target.value !== curr.target.value))),
    //     map(([_, curr]) => ({
    //       value: (curr.target as HTMLInputElement | HTMLTextAreaElement).value,
    //       target: domSerializer.getNodeId(curr.target as (HTMLInputElement | HTMLTextAreaElement)),
    //     })),
    //   ),
    // ),
    recOpts(
      [window],
      'mousemove',
      ActionType.MOUSE_EVENT,
      pipe(simplifyPointsWithTimeout(500), map(({ x, y }) => ({ x, y, click: false, leave: false }))),
    ),
    recOpts(
      [window],
      'mouseout',
      ActionType.MOUSE_EVENT,
      map(({ x, y }) => ({ x, y, click: false, leave: true })),
    ),
    recOpts(
      [window],
      'click',
      ActionType.MOUSE_EVENT,
      map(({ x, y }) => ({ x, y, click: true, leave: false })),
    ),
    recOpts(
      [window],
      'touchstart',
      ActionType.TOUCH_EVENT,
      pipe(
        map((e: TouchEvent) => ({
          touches: [...e.touches].map(({ clientX: x, clientY: y, identifier: id }: Touch) => ({ x, y, id, type: 'start' })),
        })),
      ),
    ),
    recOpts(
      [window],
      'touchmove',
      ActionType.TOUCH_EVENT,
      pipe(
        throttleTime(66.67),
        map((e: TouchEvent) => ({
          touches: [...e.touches].map(({ clientX: x, clientY: y, identifier: id }: Touch) => ({ x, y, id })), // TODO: add condition for throttle/debounce based on distance. This needs a bit of involvement with untangling the multiple streams of touches
        })),
      ),
    ),
    recOpts(
      [window],
      'touchend',
      ActionType.TOUCH_EVENT,
      pipe(
        map((e: TouchEvent) => ({
          touches: [...e.touches].map(({ clientX: x, clientY: y, identifier: id }: Touch) => ({ x, y, id, type: 'end' })),
        })),
      ),
    ),
    // All the viz events are pretty similar
    recOpts([document], 'visibilitychange', ActionType.VISIBILITY_EVENT, map(() => document.visibilityState)),
    recOpts([window], 'blur', ActionType.VISIBILITY_EVENT, map(() => 'hidden')),
    recOpts([window], 'focus', ActionType.VISIBILITY_EVENT, map(() => 'visible')),
    recOpts([window], 'beforeunload', ActionType.VISIBILITY_EVENT, map(() => 'hidden')),
  ]

  // This needs to be as performant as possible, hence the hand-optimized loop
  // and the separation from the other things
  function handleMutationsList(mutationsList: MutationRecord[]) {
    const events: Omit<RecordingEventByActionType<ActionType.MUTATION_EVENT>, 'timestamp'>[] = []
    for (const mutation of mutationsList) {
      if (mutation.target instanceof Element) {
        events.push({
          type: ActionType.MUTATION_EVENT,
          data: domSerializer.serializeMutation(mutation),
        })
      }
    }
    return events
  }

  // This has to be separated out from the rest because it's weird
  const bodyMutationObserver$ = fromMutation(
    document.body, // some observed element
    { childList: true, attributes: true, subtree: true }, // observer options
  ).pipe(map(handleMutationsList), concatAll()) // concatAll has an undocumented ability to take just a plain ol array (see https://stackoverflow.com/questions/41092488/rxjs-json-data-with-an-array-processing-each-item-further-in-the-stream)

  // There is an initial event that is used to set up the recording
  // Since the window and stuff fall under this, we need to at least say what the screen size is
  const firstEvents$ = of<[RecordingEventByActionType<ActionType.SCREEN_EVENT>, RecordingEventByActionType<ActionType.SCROLL_EVENT>]>({
    timestamp: getTimestamp(),
    type: ActionType.SCREEN_EVENT,
    data: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  }, {
    timestamp: getTimestamp(),
    type: ActionType.SCROLL_EVENT,
    data: {
      target: null,
      x: document.documentElement.scrollLeft,
      y: document.documentElement.scrollTop,
    },
  })

  // The listener observables are created here and can be filtered through configuration
  const listeners$ = recorders.filter(recorder => !disableWatch[recorder.type]).map(createRecorder)
  const allEvents$ = [...listeners$, disableWatch[ActionType.MUTATION_EVENT] ? [] : bodyMutationObserver$]

  // If you know how observables work, this should be pretty self-explanatory (unironically)
  // that's the point of observables
  // return merge(firstEvents$, ...allEvents$).pipe(map((event: Omit<RecordingEventByActionType, 'timestamp'>): RecordingEventByActionType => ({ type: ActionType.UNKNOWN, ...event, timestamp: performance.now() })))
  return merge(firstEvents$, ...allEvents$).pipe(map((event: Omit<RecordingEventByActionType, 'timestamp'>): RecordingEventByActionType => ({ ...event, timestamp: getTimestamp() })))
}

// Yeah i'm addicted to fp but don't want to import a library because of bundle size
// function tryCatch(fn: () => any) {
//   try {
//     return fn()
//   }
//   catch {
//     return null
//   }
// }

// Really cool that the spec has this feature
function getLoadTimings() {
  return new Promise<PerformanceEntry>((resolve) => {
    const observer = new PerformanceObserver((list) => {
      resolve(list.getEntries()[0])
    })
    observer.observe({ type: 'navigation', buffered: true })
  })
}

// This is how everything is put together in setup
export async function recordingSetup(window: Window, document: Document, domSerializer: DOMSerializer = new DOMSerializer(), startTimestamp: number, ping: Promise<any>): Promise<RecordingSetup> {
  // console.log('The stylesheets are', document.styleSheets)

  return {
    timestamp: startTimestamp,
    // We have to be safe with stylesheets because many of them are pretty aggressively cors'd
    // styles: await Promise.all(Array.from(document.styleSheets).map(async ss => ({
    //   hash: await md5(
    //     ss.href || tryCatch(
    //       () => Array.from(ss.cssRules)
    //         .map(x => x.cssText)
    //         .sort()
    //         .join('\n'),
    //     ),
    //   ),
    //   url: ss.href,
    //   content: tryCatch(() => Array.from(ss.cssRules).map(x => x.cssText).join('\n')),
    // }))),
    styles: [], // these are not being used haha
    // innocent line that does 90% of the work here
    dom: domSerializer.serializeNode(document.documentElement)!,
    // And then a bunch of stats that are available on the window object
    // Window is unnecessary here (could be minified out) but it's nice to have to show that
    // yes, we are getting these things from the window object
    url: window.location.href,
    referrer: document.referrer,
    screen: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    browser: {
      userAgent: window.navigator.userAgent,
      hardwareConcurrency: window.navigator.hardwareConcurrency,

      doNotTrack: window.navigator.doNotTrack,
      referrer: document.referrer,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      availableScreenSize: `${window.screen.availWidth}x${window.screen.availHeight}`,
      colorDepth: window.screen.colorDepth,
      // and then i get hypocritical and stop using window here... oh well
      // @ts-expect-error - userLanguage is non-standard
      browserLanguage: navigator.language || (navigator.userLanguage),
      timezoneOffset: new Date().getTimezoneOffset(),
      cookiesEnabled: navigator.cookieEnabled,
      external: await ping,
    },
    loadTimings: await getLoadTimings(),

    // this is necessary to record stats on the engines that are active
    // raw count numbers depend on these setup events going through and being processed by lambdas
    activeEngines: engineGlobals.activeEngines,
  }
}
